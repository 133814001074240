import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import VueTranslate from "vue-translate-plugin";
import VueNoty from "vuejs-noty";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import VueMask from "v-mask";
import VueRouter from "vue-router";
import Vco from "v-click-outside";
import VueSweetalert2 from "vue-sweetalert2";
import VueClipboard from "vue-clipboard2";
import VueTheMask from "vue-the-mask";
import Paginate from "vuejs-paginate";
import App from "./App.vue";
import router from "./router/routes";
import store from "./state/store/index.js";
import "./state/helpers/index.js";

import "@/design/index.scss";

Vue.use(VueRouter)
Vue.use(Vco)

Vue.config.productionTip = false

Vue.use(VueTranslate);

Vue.use(VueNoty);

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(require("vue-chartist"))
Vue.use(VueSweetalert2)
Vue.use(VueClipboard)
Vue.component("apexchart", VueApexCharts)
Vue.component("paginate", Paginate);
Vue.use(VueTheMask)

import firebase from "firebase/app";
import "firebase/analytics";

var firebaseConfig = {
  apiKey: "AIzaSyCqmh-2YE9-C3vCDQnJ9YHJyUJ0zmsGNd0",
  authDomain: "m2n-influenciadores.firebaseapp.com",
  databaseURL: "https://m2n-influenciadores-default-rtdb.firebaseio.com",
  projectId: "m2n-influenciadores",
  storageBucket: "m2n-influenciadores.appspot.com",
  messagingSenderId: "27243906864",
  appId: "1:27243906864:web:d953d11ad5492c15636af5",
  measurementId: "G-1DMSWTX2RG"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")