import Vue from 'vue';
import Router from 'vue-router';
import appConfig from '../../src/app.config';
import { api } from '@/state/services';

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "Home",
      path: "/",
      component: () => import("./views/home"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Home",
      },
    },
    {
      name: "Login",
      path: "/login",
      component: () => import("./views/login"),
      meta: {
        authRequired: false,
        title: appConfig.title + " › Login",
      },
    },
    {
      name: "Logging in",
      path: "/login/token/:token",
      component: () => import("./views/login-in"),
      meta: {
        authRequired: false,
        title: appConfig.title + " › Logging in",
      },
    },
    {
      name: "Sair",
      path: "/logout",
      redirect: "/login",
    },
    {
      name: "Cadastre-se",
      path: "/register",
      component: () => import("./views/register"),
      meta: {
        authRequired: false,
        title: appConfig.title + " › Cadastre-se",
      },
    },
    {
      name: "Cadastre-se › Anjo Solidário",
      path: "/register/:sponsor",
      component: () => import("./views/register"),
      meta: {
        authRequired: false,
        title: appConfig.title + " › Cadastre-se › Anjo Solidário",
      },
    },
    {
      name: "Esqueceu sua senha?",
      path: "/password-forgot",
      component: () => import("./views/password-forgot"),
      meta: {
        authRequired: false,
        title: appConfig.title + " › Esqueceu sua senha?",
      },
    },
    {
      name: "Alterar senha",
      path: "/password-change",
      component: () => import("./views/password-change"),
      meta: {
        authRequired: false,
        title: appConfig.title + " › Alterar senha",
      },
    },
    {
      name: "Upgrade",
      path: "/upgrade",
      component: () => import("./views/upgrade"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Upgrade",
      },
    },
    {
      name: "Minha Conta",
      path: "/account",
      component: () => import("./views/account"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Minha Conta",
      },
    },
    {
      name: "Minha Senha",
      path: "/account/password",
      component: () => import("./views/account-password"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Minha Senha",
      },
    },
    {
      name: "Senha de Segurança",
      path: "/account/password/security",
      component: () => import("./views/account-password-security"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Senha de Segurança",
      },
    },
    {
      name: "Senha de Segurança › Recuperar",
      path: "/account/password/security/:token",
      component: () => import("./views/account-password-security"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Senha de Segurança › Recuperar",
      },
    },
    {
      name: "Conta Bancária",
      path: "/account/bank",
      component: () => import("./views/account-bank"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Conta Bancária",
      },
    },
    {
      name: "Bitcoin",
      path: "/bitcoin",
      component: () => import("./views/bitcoin"),
      meta: {
        authRequired: false,
        title: appConfig.title + " › Bitcoin",
      },
    },
    {
      name: "Leilão › Extrato",
      path: "/auction/extract",
      component: () => import("./views/auction/extract"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Leilão › Extrato",
      },
    },
    {
      name: "Leilão › Produtos",
      path: "/auction",
      component: () => import("./views/auction/list"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Leilão › Produtos",
      },
    },
    {
      name: "Leilão › Meus Lances",
      path: "/auction/bids",
      component: () => import("./views/auction/bids"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Leilão › Meus Lances",
      },
    },
    {
      name: "Leilão › Meus Prêmios",
      path: "/auction/awards",
      component: () => import("./views/auction/awards"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Leilão › Meus Prêmios",
      },
    },
    {
      name: "Leilão › Produto",
      path: "/auction/:id",
      component: () => import("./views/auction/view"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Leilão › Produto",
      },
    },
    {
      name: "Entidades",
      path: "/entities",
      component: () => import("./views/entities"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Entidades",
      },
    },
    {
      name: "Doar",
      path: "/wallet/deposit",
      component: () => import("./views/wallet/deposit"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Doar",
      },
    },
    {
      name: "Doar › Entidade",
      path: "/wallet/deposit/:url",
      component: () => import("./views/wallet/deposit"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Doar › Entidadey",
      },
    },
    {
      name: "Bingo › Próximas Rodadas",
      path: "/sorteios/rounds",
      component: () => import("./views/sorteios/rounds"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Bingo › Próximas Rodadas",
      },
    },
    {
      name: "Bingo › Minhas Cartelas",
      path: "/sorteios/cards",
      component: () => import("./views/sorteios/cards"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Bingo › Minhas Cartelas",
      },
    },
    {
      name: "Bingo › Pedidos",
      path: "/sorteios/orders",
      component: () => import("./views/sorteios/orders"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Bingo › Pedidos",
      },
    },
    {
      name: "Carteira › Pedido",
      path: "/wallet/order/:id",
      component: () => import("./views/wallet/order"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Carteira › Pedido",
      },
    },
    {
      name: "Carteira › Pedido › Comprovantes",
      path: "/wallet/order/deposit/receipt/:id",
      component: () => import("./views/wallet/order-deposit-receipt"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Carteira › Pedido › Comprovantes",
      },
    },
    {
      name: "Carteira › Pedido › Comprovantes › Enviar",
      path: "/wallet/order/deposit/send/:id",
      component: () => import("./views/wallet/order-deposit-send"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Carteira › Pedido › Comprovantes › Enviar",
      },
    },
    {
      name: "Carteira › Cash Back",
      path: "/wallet/extract",
      component: () => import("./views/wallet/extract-cash"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Carteira › Cash Back",
      },
    },
    {
      name: "Carteira › Transferência",
      path: "/wallet/transfer",
      component: () => import("./views/wallet/transfer"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Carteira › Transferência",
      },
    },
    {
      name: "Carteira › Saque",
      path: "/wallet/withdrawal",
      component: () => import("./views/wallet/withdrawal"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Carteira › Saque",
      },
    },
    {
      name: "Carteira › Saque › Histórico",
      path: "/wallet/withdrawal/history",
      component: () => import("./views/wallet/withdrawal-history"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Carteira › Saque › Histórico",
      },
    },
    {
      name: "Carteira › Bônus › Extrato",
      path: "/wallet/bonus/extract",
      component: () => import("./views/wallet/bonus-extract"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Carteira › Bônus › Extrato",
      },
    },
    {
      name: "Carteira › Bônus › Saldo",
      path: "/wallet/bonus/balance",
      component: () => import("./views/wallet/bonus-balance"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Carteira › Bônus › Saldo",
      },
    },
    {
      name: "Carteira › Bônus › Voucher",
      path: "/wallet/bonus/voucher",
      component: () => import("./views/wallet/bonus-voucher"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Carteira › Bônus › Voucher",
      },
    },
    {
      name: "Rede › Indicados",
      path: "/network/sponsored",
      component: () => import("./views/network/sponsored"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Rede › Indicados",
      },
    },
    {
      name: "Rede › Equipe",
      path: "/network/team",
      component: () => import("./views/network/team"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Rede › Equipe",
      },
    },
    {
      name: "Rede › Binário",
      path: "/network/binary",
      component: () => import("./views/network/binary"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Rede › Binário",
      },
    },
    {
      name: "Rede › Binário › Árvore",
      path: "/network/binary/:id",
      component: () => import("./views/network/binary"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Rede › Binário › Árvore",
      },
    },
    {
      name: "Relatórios › Indicação",
      path: "/reports/referral",
      component: () => import("./views/reports/referral"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Relatórios › Indicação",
      },
    },
    {
      name: "Relatórios › Binário",
      path: "/reports/binary",
      component: () => import("./views/reports/binary"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Relatórios › Binário",
      },
    },
    {
      name: "Relatórios › Rendimento",
      path: "/reports/profit",
      component: () => import("./views/reports/profit"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Relatórios › Rendimento",
      },
    },
    {
      name: "Relatórios › Graduação",
      path: "/reports/graduation",
      component: () => import("./views/reports/graduation"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Relatórios › Graduação",
      },
    },
    {
      name: "Minhas Doações",
      path: "/reports/orders",
      component: () => import("./views/reports/orders"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Minhas Doações",
      },
    },
    {
      name: "Downloads",
      path: "/downloads",
      component: () => import("./views/downloads"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Downloads",
      },
    },
    {
      name: "FAQ",
      path: "/faq",
      component: () => import("./views/faq"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › FAQ",
      },
    },
    {
      name: "Atendimento",
      path: "/tickets",
      component: () => import("./views/tickets"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Atendimento",
      },
    },
    {
      name: "Atendimento › Ver",
      path: "/tickets/:id",
      component: () => import("./views/tickets-view"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Atendimento › Ver",
      },
    },
    {
      name: "Notas Fiscais › Criar",
      path: "/invoices",
      component: () => import("./views/invoices"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Notas Fiscais",
      },
    },
    {
      name: "Notas Fiscais",
      path: "/invoices/create",
      component: () => import("./views/invoice-create"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Notas Fiscais",
      },
    },
    {
      name: "Sorteios",
      path: "/draws",
      component: () => import("./views/draws/list"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Sorteios",
      },
    },
    {
      name: "Sorteios › Produto",
      path: "/draws/:slug",
      component: () => import("./views/draws/list"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Sorteios › Produto",
      },
    },
    {
      name: "404",
      path: "/404",
      component: () => import("./views/404"),
      meta: {
        authRequired: false,
        title: appConfig.title + " › 404",
      },
    },
    {
      path: "*",
      redirect: "/register*",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  if (to.meta.authRequired && !loggedIn) {
    return next("/login");
  }

  if (to.meta.authRequired && loggedIn) {
    api
      .get("user")
      .then((response) => {
        if (response.data && response.data.status == "success") {
          if (
            response.data.order &&
            response.data.order.id &&
            to.name != "Carteira › Pedido"
          ) {
            return next("/wallet/order/" + response.data.order.id);
          }
        } else {
          return next("/login");
        }
      })
      .catch(error => {
        if (error) {
          return next("/login");
        }
      });
  }

  document.title = to.meta.title;
  next();
});

export default router;